import React, { useCallback, useEffect, useState } from "react"
import clsx from "clsx"
import { useParallax } from "react-scroll-parallax"

import Carousel from "../../components/Carousel"
import Button from "../../components/Button"
import { useWallet } from "../../hooks/Wallet"
import { CarouselImage, Color, Ornament, RichText, TokenAmount } from "../../types"

import styles from "./Gallery.module.scss"

interface GalleryProps {
  title: string
  description: RichText[]
  images: CarouselImage[]
  color: Color
  shouldFetchAmount: boolean
  withButton?: boolean
  withGradient?: boolean
  withBackground?: boolean
  topOrnamnet?: Ornament
  bottomOrnamnet?: Ornament
}

function mapRichText(description: RichText[], color: Color): JSX.Element[] {
  return description.map(({ content, colored }, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <span key={index} className={colored ? styles[color] : undefined}>
      {content}
    </span>
  ))
}

function isDisabled(tokenAmount: TokenAmount | null): boolean {
  if (tokenAmount !== null) {
    const [saplied, max] = tokenAmount

    return max <= saplied;
  }

  return false;
}

function Gallery({
  title,
  description,
  images,
  color,
  shouldFetchAmount,
  withButton = false,
  withGradient = false,
  withBackground = false,
  topOrnamnet,
  bottomOrnamnet,
}: GalleryProps) {
  const { ref } = useParallax<HTMLParagraphElement>({ speed: 5 })
  const [slideIndex, setSlideIndex] = useState<number>(0)
  const [tokenAmount, setTokenAmount] = useState<TokenAmount | null>(null)
  const { connectWallet, getTokenAmount, donate, isWalletConnected, fetching } = useWallet()

  const donateHandler = useCallback(async () => {
    const success = await donate(images[slideIndex].ID, images[slideIndex].price)

    if (success) {
      const amount = await getTokenAmount(images[slideIndex].ID)

      if (amount) setTokenAmount(amount)
    }
  }, [images, slideIndex, donate, getTokenAmount])

  useEffect(() => {
    async function tokens() {
      const amount = await getTokenAmount(images[slideIndex].ID)

      if (amount) setTokenAmount(amount)
    }

    if (isWalletConnected && shouldFetchAmount) tokens()
  }, [images, shouldFetchAmount, slideIndex, isWalletConnected, getTokenAmount])

  return (
    <section className={clsx(styles.root, !withButton && styles["without-button"])}>
      {withBackground && <div className={styles.background} />}
      {topOrnamnet && (
        <div
          className={styles.ornament}
          style={{ backgroundImage: `url(${topOrnamnet.url})`, height: topOrnamnet.height }}
        />
      )}
      <div className={clsx(styles.info, styles.offset, topOrnamnet && styles["top-offset"])}>
        <h2 className={clsx(styles.title, styles[color])}>{title}</h2>
        <p ref={ref} className={styles.description}>
          {mapRichText(description, color)}
        </p>
      </div>
      <Carousel
        slides={images}
        color={color}
        initialSlide={slideIndex}
        tokenAmount={tokenAmount}
        withGradient={withGradient}
        buttonDisabled={fetching}
        onActiveIndexChange={setSlideIndex}
      />
      {withButton && (
        <div className={styles["btn-wrapper"]}>
          {isWalletConnected ? (
            <Button disabled={fetching || isDisabled(tokenAmount)} onClick={donateHandler}>
              Donate
            </Button>
          ) : (
            <Button disabled={fetching} onClick={connectWallet}>
              Connect Wallet
            </Button>
          )}
        </div>
      )}
      {bottomOrnamnet && (
        <div
          className={styles.ornament}
          style={{ backgroundImage: `url(${bottomOrnamnet.url})`, height: bottomOrnamnet.height }}
        />
      )}
    </section>
  )
}

export default Gallery
