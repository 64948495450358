import React from "react"

import { Link } from "../../types"
import Icon from "./Icon"

import styles from "./Footer.module.scss"

interface FooterProps {
  links: Link[]
}

export default function Footer({ links }: FooterProps) {
  return (
    <footer className={styles.root}>
      <Icon size={40} color="#FFF" />
      <ul className={styles.links}>
        {links.map((link) => (
          <li key={link.label} className={styles.link}>
            <a href={link.url}>{link.label}</a>
          </li>
        ))}
      </ul>
      <Icon size={40} color="white" />
    </footer>
  )
}
