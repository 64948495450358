import React from "react"
import { useParallax } from "react-scroll-parallax"

import styles from "./Hero.module.scss"

function Hero() {
  const { ref } = useParallax<HTMLDivElement>({ speed: 5 })

  return (
    <section className={styles.root}>
      <div className={styles["image-wrapper"]}>
        <img className={styles.image} src={`${process.env.PUBLIC_URL}/img/hero.png`} alt="hero" title="hero" />
      </div>
      <div ref={ref} className={styles.description}>
        <p>All the Ukrainians, united like never before, are here for our Homeland to bring victory closer.</p>
        <p>
          As a team that wants to make its impact on peace, democracy, and human dignity, we created an original
          thematic NFT collection.
        </p>
        <p>We will donate 100 % of the revenue to support Ukraine, our army, and volunteers.</p>
      </div>
      <div className={styles["tag-wrapper"]}>
        <span className={styles.tag}>#StandWithUkraine</span>
      </div>
    </section>
  )
}

export default Hero
