import clsx from "clsx"
import React from "react"

import styles from "./Button.module.scss"

interface ButtonProps {
  children: React.ReactNode
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

function Button({ children, disabled, onClick }: ButtonProps) {
  return (
    <button
      type="button"
      className={clsx(styles.root, disabled && styles.disabled)}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
