import { ModalContent } from "../../components/Modal/Modal"
import { ErrorMsg, NetworkConfig, SuccessMsg } from "./types"

export function isMetaMaskInstalled(): boolean {
  const { ethereum } = window

  return typeof ethereum !== "undefined" && ethereum.isMetaMask
}

export async function fetchNetworkConfig(): Promise<NetworkConfig> {
  const url = `${process.env.PUBLIC_URL}/config/network.json`
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
  const contract = await response.json()

  return contract
}

export async function fetchAbi(): Promise<any> {
  const url = `${process.env.PUBLIC_URL}/config/abi.json`
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
  const contract = await response.json()

  return contract
}

/**
 * @link https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
 */
export function isMobile(): boolean {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem))
}

export function getModalContent(error: ErrorMsg | null, success: SuccessMsg | null): ModalContent | null {
  if (error) {
    return {
      heading: "Oops!",
      description: error,
    }
  }

  if (success) {
    return {
      heading: "Congratulations!",
      description: success,
    }
  }

  return null
}
