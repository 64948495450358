import React from "react"

import { WalletContext } from "./types"

const defaultContext: WalletContext = {
  connectWallet: () => {},
  getTokenAmount: () => Promise.resolve(),
  donate: () => Promise.resolve(),
  isWalletConnected: false,
  fetching: false,
}

const walletContext = React.createContext<WalletContext>(defaultContext)

export default walletContext
