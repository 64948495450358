import React from "react"

interface IconProps {
  size: number
  color: string
}

function Icon({ size, color }: IconProps) {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.8437 39.9999C28.7969 39.9999 19.8438 31.0468 19.8438 20C19.8438 8.9531 28.7969 0 39.8437 0C39.8437 11.0156 30.9375 19.9531 19.9219 20C30.9218 20.0468 39.8437 28.9843 39.8437 39.9999Z"
        fill={color}
      />
      <path
        d="M20 19.9531V20C20 20.0156 20 20.0312 20 20.0468C19.9843 31.0468 11.0781 39.953 0.0937498 39.9999C0.0624999 39.9999 0.03125 39.9999 0.015625 39.9999V39.828C0.015625 39.8905 0.015625 39.9374 0.015625 39.9999C0.015625 28.9531 8.96873 20 20.0156 20C8.96873 20 0.015625 11.0468 0.015625 0C11.0312 0 19.9687 8.93747 20 19.9531Z"
        fill={color}
      />
    </svg>
  )
}

export default Icon
