import React, { useState } from "react"

import { Color } from "../../types"

import styles from "./ArrowButton.module.scss"

type IconVariant = "left" | "right" | "left-hover" | "right-hover"

type Variant = Extract<IconVariant, "left" | "right">

interface ArrowIconProps {
  variant: IconVariant
  color: Color
  size?: number
}

interface ArrowButtonProps {
  variant: Variant
  color: Color
  className?: string
  disabled?: boolean
}

function getVariant(variant: Variant, onHover: boolean): IconVariant {
  if (variant === "left") return onHover ? "left-hover" : "left"

  return onHover ? "right-hover" : "right"
}

function ArrowIcon({ variant, color, size = 50 }: ArrowIconProps) {
  switch (variant) {
    case "left":
      return (
        <svg
          width={size}
          height={size}
          viewBox={`0 0 ${size} ${size}`}
          xmlns="http://www.w3.org/2000/svg"
          className={styles[color]}
        >
          <path d="M24.7619 11.4277H30.238V5.95155L24.7619 5.95155V11.4277Z" />
          <path d="M30.2382 5.87109L35.7144 5.87109V0.394905L30.2382 0.394905V5.87109Z" />
          <path d="M19.2855 16.9844H24.7617V11.5082H19.2855V16.9844Z" />
          <path d="M8.33337 27.9355H13.8096V22.4594H8.33337V27.9355Z" />
          <path d="M24.7619 44.4453H30.238V38.9691H24.7619V44.4453Z" />
          <path d="M30.2382 50H35.7144V44.5238H30.2382V50Z" />
          <path d="M19.2855 38.8887H24.7617V33.4125H19.2855V38.8887Z" />
          <path d="M13.8097 22.4609H19.2859V16.9847H13.8097V22.4609Z" />
          <path d="M13.8097 33.4102H19.2859V27.934H13.8097V33.4102Z" />
        </svg>
      )
    case "right":
      return (
        <svg
          width={size}
          height={size}
          viewBox={`0 0 ${size} ${size}`}
          xmlns="http://www.w3.org/2000/svg"
          className={styles[color]}
        >
          <path d="M25.2381 38.5723H19.762V44.0485H25.2381V38.5723Z" />
          <path d="M19.7618 44.1289H14.2856V49.6051H19.7618V44.1289Z" />
          <path d="M30.7145 33.0156H25.2383V38.4918H30.7145V33.0156Z" />
          <path d="M41.6666 22.0645H36.1904V27.5406H41.6666V22.0645Z" />
          <path d="M25.2381 5.55469H19.762V11.0309H25.2381V5.55469Z" />
          <path d="M19.7618 0L14.2856 0V5.47619H19.7618V0Z" />
          <path d="M30.7145 11.1113H25.2383V16.5875H30.7145V11.1113Z" />
          <path d="M36.1903 27.5391H30.7141V33.0153H36.1903V27.5391Z" />
          <path d="M36.1903 16.5898H30.7141V22.066H36.1903V16.5898Z" />
        </svg>
      )
    case "left-hover":
      return (
        <svg
          width={size}
          height={size}
          viewBox={`0 0 ${size} ${size}`}
          xmlns="http://www.w3.org/2000/svg"
          className={styles[color]}
        >
          <path d="M24.7619 11.4277H30.238V5.95155L24.7619 5.95155V11.4277Z" />
          <path d="M30.2382 5.87109L35.7144 5.87109V0.394905L30.2382 0.394905V5.87109Z" />
          <path d="M19.2855 16.9844H24.7617V11.5082H19.2855V16.9844Z" />
          <path d="M8.33337 27.9355H13.8096V22.4594H8.33337V27.9355Z" />
          <path d="M24.7619 44.4453H30.238V38.9691H24.7619V44.4453Z" />
          <path d="M30.2382 50H35.7144V44.5238H30.2382V50Z" />
          <path d="M19.2855 38.8887H24.7617V33.4125H19.2855V38.8887Z" />
          <path d="M13.8097 22.4609H19.2859V16.9847H13.8097V22.4609Z" />
          <path d="M13.8097 33.4102H19.2859V27.934H13.8097V33.4102Z" />
          <path d="M35.7619 11.4277H41.238V5.95155L35.7619 5.95155V11.4277Z" />
          <path d="M41.2382 5.87109L46.7144 5.87109V0.394905L41.2382 0.394905V5.87109Z" />
          <path d="M30.2855 16.9844H35.7617V11.5082H30.2855V16.9844Z" />
          <path d="M19.3334 27.9355H24.8096V22.4594H19.3334V27.9355Z" />
          <path d="M35.7619 44.4453H41.238V38.9691H35.7619V44.4453Z" />
          <path d="M41.2382 50H46.7144V44.5238H41.2382V50Z" />
          <path d="M30.2855 38.8887H35.7617V33.4125H30.2855V38.8887Z" />
          <path d="M24.8097 22.4609H30.2859V16.9847H24.8097V22.4609Z" />
          <path d="M24.8097 33.4102H30.2859V27.934H24.8097V33.4102Z" />
        </svg>
      )
    case "right-hover":
      return (
        <svg
          width={size}
          height={size}
          viewBox={`0 0 ${size} ${size}`}
          xmlns="http://www.w3.org/2000/svg"
          className={styles[color]}
        >
          <path d="M25.2381 38.5723H19.762V44.0485H25.2381V38.5723Z" />
          <path d="M19.7618 44.1289H14.2856V49.6051H19.7618V44.1289Z" />
          <path d="M30.7145 33.0156H25.2383V38.4918H30.7145V33.0156Z" />
          <path d="M41.6666 22.0645H36.1904V27.5406H41.6666V22.0645Z" />
          <path d="M25.2381 5.55469H19.762V11.0309H25.2381V5.55469Z" />
          <path d="M19.7618 0H14.2856V5.47619H19.7618V0Z" />
          <path d="M30.7145 11.1113H25.2383V16.5875H30.7145V11.1113Z" />
          <path d="M36.1903 27.5391H30.7141V33.0153H36.1903V27.5391Z" />
          <path d="M36.1903 16.5898H30.7141V22.066H36.1903V16.5898Z" />
          <path d="M14.2381 38.5723H8.76196V44.0485H14.2381V38.5723Z" />
          <path d="M8.76184 44.1289H3.28564V49.6051H8.76184V44.1289Z" />
          <path d="M19.7145 33.0156H14.2383V38.4918H19.7145V33.0156Z" />
          <path d="M30.6666 22.0645H25.1904V27.5406H30.6666V22.0645Z" />
          <path d="M14.2381 5.55469H8.76196V11.0309H14.2381V5.55469Z" />
          <path d="M8.76184 0H3.28564V5.47619H8.76184V0Z" />
          <path d="M19.7145 11.1113H14.2383V16.5875H19.7145V11.1113Z" />
          <path d="M25.1903 27.5391H19.7141V33.0153H25.1903V27.5391Z" />
          <path d="M25.1903 16.5898H19.7141V22.066H25.1903V16.5898Z" />
        </svg>
      )
    default:
      throw new Error("Wrong varinat for Arrow Icon")
  }
}

const ArrowButton = React.forwardRef<HTMLButtonElement, ArrowButtonProps>((props, ref) => {
  const [onHover, setOnHover] = useState<boolean>(false)

  return (
    <button
      ref={ref}
      className={props.className}
      type="button"
      disabled={props.disabled}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <ArrowIcon variant={getVariant(props.variant, onHover)} color={props.color} />
    </button>
  )
})

export default ArrowButton
