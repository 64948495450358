import React from "react"
import { useSwiperSlide } from "swiper/react"

import { CarouselImage, TokenAmount } from "../../types"

import styles from "./Image.module.scss"

interface ImageProps extends CarouselImage {
  tokenAmount: TokenAmount | null
  withGradient?: boolean
}

function getTitleFromAmount(tokenAmount: TokenAmount): string {
  const [supplied, max] = tokenAmount

  return `${max - supplied} / ${max}`
}

function getPriceString(price: string): string {
  return `${price} ETH`
}

function isExtendedTitle(isActive: boolean, tokenAmount: TokenAmount | null): boolean {
  return isActive && tokenAmount !== null
}

function Image({ src, title, label, price, withGradient = false, tokenAmount }: ImageProps) {
  const { isActive } = useSwiperSlide()
  let titleElement: JSX.Element

  if (isExtendedTitle(isActive, tokenAmount)) {
    titleElement = (
      <>
        <div className={styles["extended-title"]}>
          <h3 className={styles.heading}>{title}</h3>
          <h4 className={styles.amount}>{getTitleFromAmount(tokenAmount!)}</h4>
        </div>
        <div className={styles.price}>{getPriceString(price)}</div>
      </>
    )
  } else {
    titleElement = <h3 className={styles.title}>{title}</h3>
  }

  return (
    <div>
      <img className={styles.image} src={src} alt={title} title={title} />
      {withGradient && <div className={styles.gradient} />}
      <div className={styles.label}>{label}</div>
      {titleElement}
    </div>
  )
}

export default Image
