import React from "react"
import { EffectCoverflow, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import clsx from "clsx"

import Image from "./Image"
import ArrowButton from "./ArrowButton"
import { CarouselImage, Color, TokenAmount } from "../../types"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/effect-coverflow"
import styles from "./Carousel.module.scss"

interface CarouselProps {
  slides: CarouselImage[]
  color: Color
  initialSlide: number
  tokenAmount: TokenAmount | null
  withGradient?: boolean
  buttonDisabled?: boolean
  onActiveIndexChange: (index: number) => void
}

function Carousel({
  slides,
  color,
  initialSlide,
  tokenAmount,
  withGradient = false,
  buttonDisabled = false,
  onActiveIndexChange,
}: CarouselProps) {
  return (
    <Swiper
      className={styles.root}
      navigation={{ prevEl: `.${styles.prev}`, nextEl: `.${styles.next}`, disabledClass: styles.disabled }}
      pagination={{ el: ".pagination", bulletActiveClass: styles.active, bulletClass: styles.bullet }}
      initialSlide={initialSlide}
      centeredSlides
      allowSlidePrev={!buttonDisabled}
      allowSlideNext={!buttonDisabled}
      breakpoints={{
        320: {
          slidesPerView: 1.1,
          spaceBetween: 500,
        },
        900: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      }}
      effect="coverflow"
      modules={[EffectCoverflow, Navigation, Pagination]}
      coverflowEffect={{
        rotate: 0,
        stretch: 200,
        depth: 200,
        modifier: 1,
        slideShadows: false,
      }}
      onSlideChange={(swiper) => onActiveIndexChange(swiper.activeIndex)}
    >
      {slides.map(({ src, title, label, price, ID }) => (
        <SwiperSlide key={ID}>
          <Image
            src={src}
            title={title}
            label={label}
            price={price}
            ID={ID}
            withGradient={withGradient}
            tokenAmount={tokenAmount}
          />
        </SwiperSlide>
      ))}
      <div className={clsx("pagination", styles.pagination)} />
      <ArrowButton
        disabled={buttonDisabled}
        variant="left"
        color={color}
        className={clsx(styles.button, styles.prev)}
      />
      <ArrowButton
        disabled={buttonDisabled}
        variant="right"
        color={color}
        className={clsx(styles.button, styles.next)}
      />
    </Swiper>
  )
}

export default Carousel
