import React from "react"
import clsx from "clsx"
import { useParallax } from "react-scroll-parallax"

import CrossIcon from "../../components/CrossIcon"

import styles from "./About.module.scss"

function About() {
  const { ref: coloredTextNftRef } = useParallax<HTMLParagraphElement>({ speed: 5 })
  const { ref: coloredTextProjectRef } = useParallax<HTMLParagraphElement>({ speed: 5 })
  const { ref: textNftRef } = useParallax<HTMLParagraphElement>({ speed: 5 })
  const { ref: textProjectRef } = useParallax<HTMLParagraphElement>({ speed: 5 })

  return (
    <section className={styles.root}>
      <div className={styles.block}>
        <h3 className={styles.heading}>About NFT</h3>
        <div className={styles.description}>
          <CrossIcon size={14} color="yellow" className={styles.icon} />
          <div className={styles.paragraph}>
            <p ref={coloredTextNftRef} className={clsx(styles["color-text"], styles.yellow)}>
              Our thematic collection is named Ukrainian Chicks, and it&apos;s inspired by the bravest and the most
              devoted women in the world.
            </p>
            <p ref={textNftRef} className={styles.text}>
              It includes three series; each will contain three NFTs with different circulation, rarity, and price (1/1
              epic — 0.5eth, 1/10 rare — 0.1eth, 1/100 common — 0.02eth).
            </p>
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <h3 className={styles.heading}>About us</h3>
        <div className={styles.description}>
          <CrossIcon size={14} color="blue" className={styles.icon} />
          <div>
            <p ref={coloredTextProjectRef} className={clsx(styles["color-text"], styles.blue)}>
              Our main project consists of three collections: Devil Chicks, Night Chicks, and Holy Chicks.
            </p>
            <p ref={textProjectRef} className={styles.text}>
              The first one has already been released and sold out, and the second one is under development. Along with
              donating to Ukraine, a token holder from this collection will get an NFT airdrop from the next Night
              Chicks collection according to their rarity level token from the Ukrainian Chicks.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
