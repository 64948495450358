import { ethers } from "ethers"
import { TokenAmount } from "../../types"

type NetworkName = "Ethereum"

type NetworkSymbol = "ETH"

type ChainID = string

type MarketPlace = "Opeansea"

type Signer = ethers.providers.JsonRpcSigner

type Contract = ethers.Contract

interface Network {
  name: NetworkName
  symbol: NetworkSymbol
  chainID: ChainID
}

export interface ProviderRpcError extends Error {
  message: string
  code: number
  data?: unknown
}

export enum ErrorMsg {
  NO_METAMASK = "Please install MetaMask",
  CONNECT_WALLET = "Could not connect wallet",
  NETWORK_CONFIG = "Could not fetch network configuration",
  NETWORK_ID = "Please choose main network",
  ABI = "Could not fetch abi",
  TOKENS_AMOUNT = "Could not get amount of tokens",
  DONATE = "Failed to donate",
  ACCOUNT_CHANGED = "Acount was changed",
  CHAIN_CHANGED = "Chain was changed",
  DISCONNECT = "Disconnected",
}

export enum SuccessMsg {
  DONATE = "You have successfully donated for Ukrainian army",
}

export interface NetworkConfig {
  address: string
  scanLink: string
  network: Network
  nftName: string
  symbol: string
  marketPlace: MarketPlace
  marketPlaceLink: string
  deepLink: string
}

export type Action =
  | { type: "networkConfig"; payload: NetworkConfig }
  | { type: "abi"; payload: any }
  | { type: "connectWallet"; payload: { chainID: ChainID; signer: Signer } }
  | { type: "contract"; payload: { contract: Contract } }
  | { type: "fetching"; payload: boolean }
  | { type: "error"; payload: ErrorMsg | null }
  | { type: "success"; payload: SuccessMsg | null }
  | { type: "disconnect" }

export interface WalletState {
  networkConfig: NetworkConfig | null
  abi: any | null
  signer: Signer | null
  chainID: ChainID | null
  contract: Contract | null
  error: ErrorMsg | null
  success: SuccessMsg | null
  fetching: boolean
}

export interface WalletContext {
  connectWallet: () => void
  getTokenAmount: (index: string) => Promise<TokenAmount | void>
  donate: (index: string, price: string) => Promise<boolean | void>
  isWalletConnected: boolean
  fetching: boolean
}
