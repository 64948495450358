import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"

import { WalletProvider } from "./hooks/Wallet"
import Hero from "./sections/Hero"
import About from "./sections/About"
import Gallery from "./sections/Gallery"
import Footer from "./components/Footer"
import { footerLinks, warChicks, cityChicks, folkChicks } from "./config"

import styles from "./App.module.scss"

function App() {
  return (
    <ParallaxProvider>
      <WalletProvider>
        <main className={styles.root}>
          <Hero />
          <About />
          <Gallery
            title={warChicks.title}
            description={warChicks.description}
            images={warChicks.images}
            color={warChicks.color}
            shouldFetchAmount={!warChicks.comingSoon}
            withButton={!warChicks.comingSoon}
            withGradient={!warChicks.comingSoon}
            withBackground={warChicks.withBackground}
            topOrnamnet={warChicks.topOrnament}
            bottomOrnamnet={warChicks.bottomOrnament}
          />
          <Gallery
            title={cityChicks.title}
            description={cityChicks.description}
            images={cityChicks.images}
            color={cityChicks.color}
            shouldFetchAmount={!cityChicks.comingSoon}
            withButton={!cityChicks.comingSoon}
            withGradient={!cityChicks.comingSoon}
            withBackground={cityChicks.withBackground}
            topOrnamnet={cityChicks.topOrnament}
            bottomOrnamnet={cityChicks.bottomOrnament}
          />
          <Gallery
            title={folkChicks.title}
            description={folkChicks.description}
            images={folkChicks.images}
            color={folkChicks.color}
            shouldFetchAmount={!folkChicks.comingSoon}
            withButton={!folkChicks.comingSoon}
            withGradient={!folkChicks.comingSoon}
            withBackground={folkChicks.withBackground}
            topOrnamnet={folkChicks.topOrnament}
            bottomOrnamnet={folkChicks.bottomOrnament}
          />
          <Footer links={footerLinks} />
        </main>
      </WalletProvider>
    </ParallaxProvider>
  )
}

export default App
