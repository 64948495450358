import { useContext } from "react"

import WalletContext from "./WalletContext"

export function useWallet() {
  const context = useContext(WalletContext)

  return context
}

export default useWallet
