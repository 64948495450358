import React from "react"

import { Color } from "../../types"

import styles from "./CrossIcon.module.scss"

interface CrossIconProps {
  size: number
  color?: Color
  className?: string
}

function CrossIcon({ size, color, className }: CrossIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3396 0.660377C14.2201 1.54088 14.2201 2.94968 13.3396 3.83018L10.1698 7L13.3396 10.1698C14.2201 11.0503 14.2201 12.4591 13.3396 13.3396C12.4591 14.2201 11.0503 14.2201 10.1698 13.3396L7 10.1698L3.83019 13.3396C2.94969 14.2201 1.54088 14.2201 0.660378 13.3396C-0.220126 12.4591 -0.220126 11.0503 0.660377 10.1698L3.83019 7L0.660377 3.83018C-0.220126 2.94968 -0.220126 1.54088 0.660377 0.660377C1.54088 -0.220126 2.94968 -0.220126 3.83019 0.660377L7 3.83018L10.1698 0.660377C11.0503 -0.220127 12.4591 -0.220127 13.3396 0.660377Z"
        className={color && styles[color]}
      />
    </svg>
  )
}

export default CrossIcon
