import React, { useCallback, useEffect, useState } from "react"
import ReactModal from "react-modal"
import CrossIcon from "../CrossIcon"

import styles from "./Modal.module.scss"

ReactModal.setAppElement("#root")

export interface ModalContent {
  heading: string
  description: string
}
interface ModalProps {
  content: ModalContent | null
  onClose?: () => void
}

function Modal({ content, onClose }: ModalProps) {
  const [isOpen, setIsOpen] = useState(content !== null)

  const closeHandler = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    setIsOpen(content !== null)
  }, [content])

  return (
    <ReactModal
      contentLabel="modal"
      isOpen={isOpen}
      onRequestClose={closeHandler}
      onAfterClose={onClose}
      overlayClassName={{
        base: styles.overlay,
        afterOpen: styles["overlay-after-open"],
        beforeClose: styles["overlay-before-close"],
      }}
      className={{
        base: styles.content,
        afterOpen: styles["content-after-open"],
        beforeClose: styles["content-before-close"],
      }}
      closeTimeoutMS={1000}
    >
      <div className={styles.heading}>
        <h3 className={styles.title}>{content?.heading}</h3>
        <button className={styles["close-btn"]} aria-label="Close" type="button" onClick={() => setIsOpen(false)}>
          <CrossIcon size={20} className={styles["close-icon"]} />
        </button>
      </div>
      <p className={styles.description}>{content?.description}</p>
    </ReactModal>
  )
}

export default Modal
