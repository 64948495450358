import { WalletState, Action } from "./types"

export const initState: WalletState = {
  networkConfig: null,
  abi: null,
  error: null,
  success: null,
  signer: null,
  contract: null,
  chainID: null,
  fetching: false,
}

export function walletReducer(state: WalletState, action: Action): WalletState {
  switch (action.type) {
    case "networkConfig":
      return { ...state, networkConfig: action.payload }
    case "abi":
      return { ...state, abi: action.payload }
    case "connectWallet":
      return { ...state, signer: action.payload.signer, chainID: action.payload.chainID }
    case "contract":
      return { ...state, contract: action.payload.contract }
    case "fetching":
      return { ...state, fetching: action.payload }
    case "error":
      return { ...state, error: action.payload }
    case "success":
      return { ...state, success: action.payload }
    case "disconnect":
      return { ...state, signer: null, chainID: null, contract: null }
    default:
      throw new Error("Wrong action type")
  }
}
