/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useMemo, useReducer } from "react"
import { BigNumber, ethers } from "ethers"

import Modal from "../../components/Modal"
import WalletContext from "./WalletContext"
import { walletReducer, initState } from "./walletReducer"
import { fetchAbi, fetchNetworkConfig, getModalContent, isMetaMaskInstalled, isMobile } from "./utils"
import { TokenAmount } from "../../types"
import { ErrorMsg, ProviderRpcError, SuccessMsg } from "./types"

interface WalletProviderProps {
  children: React.ReactNode
}

function WalletProvider({ children }: WalletProviderProps) {
  const [state, dispatch] = useReducer(walletReducer, initState)
  const { signer, fetching, networkConfig, abi, contract, chainID, error, success } = state

  const connectWallet = useCallback(async () => {
    try {
      if (isMetaMaskInstalled()) {
        dispatch({ type: "fetching", payload: true })
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const chainID = await provider.send("eth_chainId", [])
        await provider.send("eth_requestAccounts", [])

        dispatch({ type: "connectWallet", payload: { chainID, signer: provider.getSigner() } })
      } else {
        if (isMobile() && networkConfig) window.location.href = networkConfig.deepLink

        dispatch({ type: "error", payload: ErrorMsg.NO_METAMASK })
      }
    } catch (error) {
      console.error(error)

      dispatch({ type: "error", payload: ErrorMsg.CONNECT_WALLET })
    } finally {
      dispatch({ type: "fetching", payload: false })
    }
  }, [networkConfig])

  const clearErrorAndSuccess = useCallback(() => {
    dispatch({ type: "error", payload: null })
    dispatch({ type: "success", payload: null })
  }, [])

  const getTokenAmount = useCallback(
    async (collectionID: string) => {
      if (contract) {
        try {
          dispatch({ type: "fetching", payload: true })
          const totalRaw: BigNumber[] = await Promise.all([
            contract.maxTokenSupply(collectionID),
            contract.totalSupply(collectionID),
          ])
          const [maxAmountRaw, suppliedAmountRaw] = totalRaw
          const max = maxAmountRaw.toNumber()
          const supplied = suppliedAmountRaw.toNumber()
          const tokenAmount: TokenAmount = [supplied, max]

          return tokenAmount
        } catch (error) {
          console.error(error)

          dispatch({ type: "error", payload: ErrorMsg.TOKENS_AMOUNT })
        } finally {
          dispatch({ type: "fetching", payload: false })
        }
      }
    },
    [contract]
  )

  const donate = useCallback(
    async (collectionID: string, price: string) => {
      if (contract) {
        try {
          dispatch({ type: "fetching", payload: true })
          const amount = 1
          const transactionResponse = await contract.mint(collectionID, amount, {
            /**
             * @description gas price should be adjusted by metamask
             * @example gasPrice: ethers.utils.parseUnits("20", "gwei")
             */
            gasLimit: "60000",
            value: ethers.utils.parseEther(price),
          })
          const transactionReceipt = await transactionResponse.wait()
          const success = transactionReceipt.status === 1

          if (success) dispatch({ type: "success", payload: SuccessMsg.DONATE })
          return success
        } catch (error) {
          console.error(error)

          dispatch({ type: "error", payload: ErrorMsg.DONATE })
        } finally {
          dispatch({ type: "fetching", payload: false })
        }
      }
    },
    [contract]
  )

  useEffect(() => {
    async function getNetworkConfig() {
      try {
        dispatch({ type: "fetching", payload: true })
        const config = await fetchNetworkConfig()

        dispatch({ type: "networkConfig", payload: config })
      } catch (error) {
        console.error(error)

        dispatch({ type: "error", payload: ErrorMsg.NETWORK_CONFIG })
      } finally {
        dispatch({ type: "fetching", payload: false })
      }
    }

    getNetworkConfig()
  }, [])

  useEffect(() => {
    async function getAbi() {
      try {
        dispatch({ type: "fetching", payload: true })
        const abi = await fetchAbi()

        dispatch({ type: "abi", payload: abi })
      } catch (error) {
        console.error(error)

        dispatch({ type: "error", payload: ErrorMsg.ABI })
      } finally {
        dispatch({ type: "fetching", payload: false })
      }
    }

    getAbi()
  }, [])

  useEffect(() => {
    if (signer && networkConfig && abi && chainID) {
      const { address, network } = networkConfig

      if (chainID === network.chainID) {
        const contract = new ethers.Contract(address, abi, signer)

        dispatch({ type: "contract", payload: { contract } })
      } else {
        /**
         * @see disconnect wallet
         */
        dispatch({ type: "disconnect" })
        dispatch({ type: "error", payload: ErrorMsg.NETWORK_ID })
      }
    }
  }, [signer, abi, networkConfig, chainID])

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", () => {
        dispatch({ type: "disconnect" })
        // dispatch({ type: "error", payload: ErrorMsg.ACCOUNT_CHANGED })
      })
      window.ethereum.on("chainChanged", () => {
        dispatch({ type: "disconnect" })
        // dispatch({ type: "error", payload: ErrorMsg.CHAIN_CHANGED })
      })
      window.ethereum.on("disconnect", (error: ProviderRpcError) => {
        console.error(error)
        dispatch({ type: "disconnect" })
        dispatch({ type: "error", payload: ErrorMsg.DISCONNECT })
      })
    }
  }, [])

  const value = useMemo(
    () => ({ connectWallet, getTokenAmount, donate, isWalletConnected: !!contract, fetching }),
    [connectWallet, getTokenAmount, donate, contract, fetching]
  )

  return (
    <WalletContext.Provider value={value}>
      <Modal content={getModalContent(error, success)} onClose={clearErrorAndSuccess} />
      {children}
    </WalletContext.Provider>
  )
}

export default WalletProvider
