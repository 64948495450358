import { CarouselImage, Color, Link, Ornament, RichText } from "./types"

interface Galerry {
  title: string
  description: RichText[]
  images: CarouselImage[]
  color: Color
  comingSoon: boolean
  withBackground?: boolean
  topOrnament?: Ornament
  bottomOrnament?: Ornament
}

export const warChicks: Galerry = {
  title: "War Chicks",
  description: [
    {
      content:
        "Military uniforms, like the second skin, look awesome on these chicks. Just yesterday, they drank latte at lunchtime at work, and today they joined the ranks of our army. ",
    },
    { content: "Hot as hell, always ready to kick all the bastards out of our land. ", colored: true },
    {
      content: "Favorite accessories: stylish helmets, blue-and-yellow makeup, and loaded guns. Get the party started!",
    },
  ],
  images: [
    {
      title: "Soldier Khrystia",
      src: `${process.env.PUBLIC_URL}/img/war-chicks/chick-0.jpg`,
      label: "common",
      price: "0.02",
      ID: "0",
    },
    {
      title: "Gunner Hanna",
      src: `${process.env.PUBLIC_URL}/img/war-chicks/chick-1.jpg`,
      label: "rare",
      price: "0.1",
      ID: "1",
    },
    {
      title: "Major Marichka",
      src: `${process.env.PUBLIC_URL}/img/war-chicks/chick-2.jpg`,
      label: "epic",
      price: "0.5",
      ID: "2",
    },
  ],
  color: "blue",
  comingSoon: false,
  bottomOrnament: {
    url: `${process.env.PUBLIC_URL}/img/war-chicks/ornament.PNG`,
    height: 51,
  },
}

export const cityChicks: Galerry = {
  title: "City Chicks",
  description: [
    {
      content: "There are no enemies in the whole world that could force them to leave their cities. ",
    },
    { content: "Before the war, they could have been anyone: ", colored: true },
    {
      content:
        "beauty bloggers, IT people, or even hippies. But now, life for them means 24/7 territory defense and volunteering, baby. If someone needs to find body armor or unicorn skin in the middle of the night, or perform complicated surgery in the basement, just give them a call.",
    },
  ],
  images: [
    {
      title: "Territorial Defender Olenka",
      src: `${process.env.PUBLIC_URL}/img/city-chicks/chick-3.jpg`,
      label: "common",
      price: "0.02",
      ID: "3",
    },
    {
      title: "Animal Rescuer Lesia",
      src: `${process.env.PUBLIC_URL}/img/city-chicks/chick-4.jpg`,
      label: "rare",
      price: "0.1",
      ID: "4",
    },
    {
      title: "Combat Medic Yuliana",
      src: `${process.env.PUBLIC_URL}/img/city-chicks/chick-5.jpg`,
      label: "epic",
      price: "0.5",
      ID: "5",
    },
  ],
  color: "yellow",
  comingSoon: false,
  withBackground: false,
}

export const folkChicks: Galerry = {
  title: "Folk Chicks",
  description: [
    {
      content: "They know their roots, and their loud voices singing traditional folk songs pulsate in our veins. ",
    },
    { content: "They symbolize the very essence of the Ukrainian soul, ", colored: true },
    {
      content:
        "and they are ready to turn any enemy into ashes and embrace any Ukrainian like a mother. Gorgeous as our Homeland itself, these chicks are eternal and unbroken. And let the thunder strike those who dare to mess with them!",
    },
  ],
  images: [
    {
      title: "Stork Solomiia",
      src: `${process.env.PUBLIC_URL}/img/folk-chicks/chick-6.jpg`,
      label: "common",
      price: "0.02",
      ID: "6",
    },
    {
      title: "Witch Yavdokha",
      src: `${process.env.PUBLIC_URL}/img/folk-chicks/chick-7.jpg`,
      label: "rare",
      price: "0.1",
      ID: "7",
    },
    {
      title: "Soul Roksoliana",
      src: `${process.env.PUBLIC_URL}/img/folk-chicks/chick-8.jpg`,
      label: "epic",
      price: "0.5",
      ID: "8",
    },
  ],
  color: "red",
  comingSoon: false,
  topOrnament: {
    url: `${process.env.PUBLIC_URL}/img/folk-chicks/ornament.png`,
    height: 131,
  },
}

export const footerLinks: Link[] = [
  { label: "Home", url: "https://chicksnft.art/" },
  { label: "Twitter", url: "https://twitter.com/chicks_nft" },
  { label: "Discord", url: "https://discord.gg/7yX9PTfryh" },
  { label: "Opensea", url: "https://opensea.io/collection/ukrainian-chicks" },
]
